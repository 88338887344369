export const tabsSchema = [
  'consejo_familiar',
  'socios',
  'asesor',
  'asesor_slim',
  'asian_desk',
  'coordinacion_general',
  'coordinacion_interna',
  // 'directivos',
  'bpo',
  // 'contabilidad',
  'impuestos',
  'impuestos_exp',
  'division_internacional',
  // 'legal',
  'marketing_digital',
  // 'internal_audit',
  // 'health',
  'sector_financiero',
  'technologies',
]

export const directorySchema = [
  {
    tab: 'consejo_familiar',
    data: [
      {
        name: 'M.I. y C.P. Javier Arreguín Ruiz ',
        job: 'job1',
        socialMedia: {
          twitter: '',
          email:
            'mailto:cpjavierarreguin@ascg.mx?subject=M.I. y C.P. Javier Arreguín Ruiz',
          linkedIn: '',
        },
      },
      {
        name: 'C.P. Angélica Sánchez Cabrera',
        job: 'job2',
        socialMedia: {
          twitter: '',
          email:
            'mailto:cpangelica@ascg.mx?subject=C.P. Angélica Sánchez Cabrera',
          linkedIn: '',
        },
      },
      {
        name: 'M.B.A y C.P.F Luis Javier Arreguín Sánchez',
        job: 'job3',
        socialMedia: {
          twitter: '',
          email:
            'mailto:luisjavier@ascg.mx?subject=C.P.F. Luis Javier Arreguín Sánchez',
          linkedIn: '',
        },
      },
      {
        name: 'M.C. Eranderi Arreguin Sánchez',
        job: 'job4',
        socialMedia: {
          twitter: '',
          email:
            'mailto:eranderiarreguin@ascg.mx?subject=M.C. Eranderi Arreguin Sánchez',
          linkedIn: '',
        },
      },
    ],
  },
  {
    tab: 'socios',
    data: [
      {
        name: 'M.F. Xiao Cheng',
        job: 'job1',
        socialMedia: {
          twitter: '',
          email: 'mailto:xcheng@ascg.mx?subject=M.F. Xiao Cheng',
          linkedIn: '',
        },
      },
      {
        name: 'Mtro. Marco Antonio López',
        job: 'job2',
        socialMedia: {
          twitter: '',
          email: 'mailto:mlopez@ascg.mx?subject=Lic. Marco Antonio López',
          linkedIn: '',
        },
      },
      {
        name: 'Lic. Alejandro Vázquez',
        job: 'job3',
        socialMedia: {
          twitter: '',
          email:
            'mailto:alejandrovazquez@ascg.mx?subject=Lic. Alejandro Vázquez',
          linkedIn: '',
        },
      },
      {
        name: 'M.D. Verónica W. Zavala',
        job: 'job3',
        socialMedia: {
          twitter: '',
          email:
            'mailto:veronicazavala@ascg.mx?subject=M.D. Verónica W. Zavala',
          linkedIn: '',
        },
      },
      {
        name: 'Lic. Sarahi Castañeda',
        job: 'job4',
        socialMedia: {
          twitter: '',
          email: 'mailto:sarahicastaneda@ascg.mx?subject=Lic. Sarahi Castañeda',
          linkedIn: '',
        },
      },
      {
        name: 'Dr. Luis Ibarra',
        job: 'job5',
        socialMedia: {
          twitter: '',
          email: 'mailto:luis.ibarra@uberprototech.com?subject=Dr. Luis Ibarra',
          linkedIn: '',
        },
      },
      {
        name: 'M.Cs. Omar Mata Juarez',
        job: 'job5',
        socialMedia: {
          twitter: '',
          email:
            'mailto:omar.mata@uberprototech.com?subject=M.Cs. Omar Mata Juarez',
          linkedIn: '',
        },
      },
      {
        name: 'Arq. Luis Enrique Flores Sierra',
        job: 'job6',
        socialMedia: {
          twitter: '',
          email:
            'mailto:luisflores@ascg.mx?subject=Arq. Luis Enrique Flores Sierra',
          linkedIn: '',
        },
      },
    ],
  },
  // {
  //   tab: 'directivos',
  //   data: [
  //     {
  //       name: 'C.P.F. Alejandro Lara De La Cruz',
  //       job: 'job7',
  //       socialMedia: {
  //         twitter: '',
  //         email:
  //           'mailto:alejandrolara@ascg.mx?subject=C.P.F. Alejandro Lara De La Cruz',
  //         linkedIn: '',
  //       },
  //     },
  //     {
  //       name: 'L.M.C. Renata Juárez',
  //       job: 'job8',
  //       socialMedia: {
  //         twitter: '',
  //         email: 'mailto:RenataJuarez@ascg.mx?subject=L.M.C. Renata Juárez',
  //         linkedIn: '',
  //       },
  //     },
  //     {
  //       name: 'Alan Elizalde',
  //       job: 'job9',
  //       socialMedia: {
  //         twitter: '',
  //         email: 'mailto:alanelizalde@ascg.mx?subject=Alan Elizalde',
  //         linkedIn: '',
  //       },
  //     },
  //   ],
  // },
  {
    tab: 'asesor',
    data: [
      {
        name: 'Alejandro Lara De La Cruz',
        job: 'job1',
        socialMedia: {
          twitter: '',
          email:
            'mailto:alejandrolara@ascg.mx?subject=C.P.F. Alejandro Lara De La Cruz',
          linkedIn: '',
        },
      },
      {
        name: 'C.P. F. Paola Cuenca',
        job: 'job2',
        socialMedia: {
          twitter: '',
          email: 'mailto:paolac@ascg.mx?subject=C.P. F. Paola Cuenca',
          linkedIn: '',
        },
      },
      {
        name: 'C.P. Carlos Miranda',
        job: 'job2',
        socialMedia: {
          twitter: '',
          email: 'mailto:carlosm@ascg.mx?subject=C.P. Carlos Miranda',
          linkedIn: '',
        },
      },
      {
        name: 'C.P. Salvador Canales',
        job: 'job2',
        socialMedia: {
          twitter: '',
          email: 'mailto:salvadorc@ascg.mx?subject=C.P. Salvador Canales',
          linkedIn: '',
        },
      },
      {
        name: 'Litzy  Flores',
        job: 'job3',
        socialMedia: {
          twitter: '',
          email: 'mailto:litzyf@ascg.mx?subject=Litzy  Flores',
          linkedIn: '',
        },
      },
      {
        name: 'Jaqueline Andrade',
        job: 'job3',
        socialMedia: {
          twitter: '',
          email: 'mailto:jaquelinea@ascg.mx?subject=Jaqueline Andrade',
          linkedIn: '',
        },
      },
      {
        name: 'Yair Retana',
        job: 'job3',
        socialMedia: {
          twitter: '',
          email: 'mailto:yairr@ascg.mx?subject=Yair Retana',
          linkedIn: '',
        },
      },
      {
        name: 'Vanesa González',
        job: 'job4',
        socialMedia: {
          twitter: '',
          email: 'mailto:vanesag@ascg.mx?subject=Vanesa González',
          linkedIn: '',
        },
      },
      {
        name: 'Uriel Quiroga',
        job: 'job4',
        socialMedia: {
          twitter: '',
          email: 'mailto:urielq@ascg.mx?subject=Uriel Quiroga',
          linkedIn: '',
        },
      },
      {
        name: 'Rodrigo Palacios',
        job: 'job4',
        socialMedia: {
          twitter: '',
          email: 'mailto:joserodrigopalacios@ascg.mx?subject=Rodrigo Palacios',
          linkedIn: '',
        },
      },
      {
        name: 'Daniel Contreras',
        job: 'job5',
        socialMedia: {
          twitter: '',
          email: 'mailto:danielc@ascg.mx?subject=Daniel Contreras',
          linkedIn: '',
        },
      },
      {
        name: 'Paulo César Santillan',
        job: 'job4',
        socialMedia: {
          twitter: '',
          email: 'mailto:paulos@ascg.mx?subject=Paulo César Santillan',
          linkedIn: '',
        },
      },
    ],
  },
  {
    tab: 'asesor_slim',
    data: [
      {
        name: 'Fernanda Mata',
        job: 'job1',
        socialMedia: {
          twitter: '',
          email: 'mailto:fernandamata@ascg.mx?subject=Fernanda Mata',
          linkedIn: '',
        },
      },
      {
        name: 'L.C. Mariana García',
        job: 'job2',
        socialMedia: {
          twitter: '',
          email: 'mailto:marianagarcia@ascg.mx?subject=L.C. Mariana García',
          linkedIn: '',
        },
      },
      {
        name: 'L.C. Alan Bello',
        job: 'job3',
        socialMedia: {
          twitter: '',
          email: 'mailto:alanbello@ascg.mx?subject=L.C. Alan Bello',
          linkedIn: '',
        },
      },
      {
        name: 'Ricardo Mendoza',
        job: 'job5',
        socialMedia: {
          twitter: '',
          email: 'mailto:ricardom@ascg.mx?subject=Ricardo Mendoza',
          linkedIn: '',
        },
      },
      {
        name: 'Diego Trejo',
        job: 'job4',
        socialMedia: {
          twitter: '',
          email: 'mailto:diegot@ascg.mx?subject=Diego Trejo',
          linkedIn: '',
        },
      },
      {
        name: 'Leonardo Montiel',
        job: 'job5',
        socialMedia: {
          twitter: '',
          email: 'mailto:leonardom@ascg.mx?subject=Leonardo Montiel',
          linkedIn: '',
        },
      },
      {
        name: 'Salma Solis',
        job: 'job6',
        socialMedia: {
          twitter: '',
          email: 'mailto:salmasolis@ascg.mx?subject=Salma Solis',
          linkedIn: '',
        },
      },
      {
        name: 'Ismael Romero',
        job: 'job5',
        socialMedia: {
          twitter: '',
          email: 'mailto:ismaelr@ascg.mx?subject=Ismael Romero',
          linkedIn: '',
        },
      },
      {
        name: 'Erika Romero',
        job: 'job7',
        socialMedia: {
          twitter: '',
          email: 'mailto:erikar@ascg.mx?subject=Erika Romero',
          linkedIn: '',
        },
      },
    ],
  },
  {
    tab: 'asian_desk',
    data: [
      {
        name: 'C.P. Charly Hernández',
        job: 'job1',
        socialMedia: {
          twitter: '',
          email: 'mailto:charlyh@ascg.mx?subject=C.P. Charly Hernández',
          linkedIn: '',
        },
      },
      {
        name: 'C.P.F. Casandra Granados',
        job: 'job2',
        socialMedia: {
          twitter: '',
          email: 'mailto:casandrag@ascg.mx?subject=C.P.F. Casandra Granados',
          linkedIn: '',
        },
      },
      {
        name: 'C.P. Rocio Vicente',
        job: 'job3',
        socialMedia: {
          twitter: '',
          email: 'mailto:rociov@ascg.mx?subject=C.P. Rocio Vicente',
          linkedIn: '',
        },
      },
      {
        name: 'L.C. Aquiles Galvan',
        job: 'job4',
        socialMedia: {
          twitter: '',
          email: 'mailto:aquilesg@ascg.mx?subject=L.C. Aquiles Galvan',
          linkedIn: '',
        },
      },
      {
        name: 'Liduvina Barrera',
        job: 'job5',
        socialMedia: {
          twitter: '',
          email: 'mailto:liduvinab@ascg.mx?subject=Liduvina Barrera',
          linkedIn: '',
        },
      },
      {
        name: 'L.C. Floridalia Hernández',
        job: 'job5',
        socialMedia: {
          twitter: '',
          email: 'mailto:floridaliah@ascg.mx?subject=L.C. Floridalia Hernández',
          linkedIn: '',
        },
      },
      {
        name: 'Alan Jair Pérez',
        job: 'job6',
        socialMedia: {
          twitter: '',
          email: 'mailto:alanp@ascg.mx?subject=Alan Jair Pérez',
          linkedIn: '',
        },
      },
      {
        name: 'Dayana Martínez',
        job: 'job5',
        socialMedia: {
          twitter: '',
          email: 'mailto:dayanam@ascg.mx?subject=Dayana Martínez',
          linkedIn: '',
        },
      },
      {
        name: 'L.C. Karina Serrano',
        job: 'job5',
        socialMedia: {
          twitter: '',
          email: 'mailto:karinas@ascg.mx?subject=L.C. Karina Serrano',
          linkedIn: '',
        },
      },
      {
        name: 'Gisel Gómez',
        job: 'job6',
        socialMedia: {
          twitter: '',
          email: 'mailto:giselg@ascg.mx?subject=Gisel Gómez',
          linkedIn: '',
        },
      },
      {
        name: 'Itzel Barrientos',
        job: 'job5',
        socialMedia: {
          twitter: '',
          email: 'mailto:itzelb@ascg.mx?subject=Itzel Barrientos',
          linkedIn: '',
        },
      },
    ],
  },
  {
    tab: 'coordinacion_general',
    data: [
      {
        name: 'C.P. Ivan Ortega Sánchez',
        job: 'job1',
        socialMedia: {
          twitter: '',
          email:
            'mailto:IvanOrtega@asxpert.mx?subject=C.P. Ivan Ortega Sánchez',
          linkedIn: '',
        },
      },
      // {
      //   name: 'Lic. Monserrat Meza',
      //   job: 'job2',
      //   socialMedia: {
      //     twitter: '',
      //     email: 'mailto:monserratm@ascg.mx?subject=Lic. Monserrat Meza',
      //     linkedIn: '',
      //   },
      // },
      // {
      //   name: 'C. Flor Canseco',
      //   job: 'job3',
      //   socialMedia: {
      //     twitter: '',
      //     email: 'mailto:florcanseco@ascg.mx?subject=C. Flor Canseco',
      //     linkedIn: '',
      //   },
      // },
      // {
      //   name: 'Mariana Rojas  ',
      //   job: 'job3',
      //   socialMedia: {
      //     twitter: '',
      //     email: 'mailto:mrojas@ascg.mx?subject=Mariana Rojas  ',
      //     linkedIn: '',
      //   },
      // },
      {
        name: 'Claudia Gutierrez',
        job: 'job2',
        socialMedia: {
          twitter: '',
          email: 'mailto:claudiaG@ascg.mx?subject=Claudia Gutierrez',
          linkedIn: '',
        },
      },
      // {
      //   name: 'Jose Genaro Arguello ',
      //   job: 'job5',
      //   socialMedia: {
      //     twitter: '',
      //     email: 'mailto:josearguello@ascg.mx?subject=Jose Genaro Arguello ',
      //     linkedIn: '',
      //   },
      // },
      // {
      //   name: 'Santiago González',
      //   job: 'job5',
      //   socialMedia: {
      //     twitter: '',
      //     email: 'mailto:santiagog@ascg.mx?subject=Santiago González',
      //     linkedIn: '',
      //   },
      // },
    ],
  },
  {
    tab: 'coordinacion_interna',
    data: [
      {
        name: 'C.P.F. Alejandro Martínez',
        job: 'job1',
        socialMedia: {
          twitter: '',
          email:
            'mailto:alejandromtz@ascg.mx?subject=C.P.F. Alejandro Martínez',
          linkedIn: '',
        },
      },
      {
        name: 'Maximiliano Molina',
        job: 'job2',
        socialMedia: {
          twitter: '',
          email: 'mailto:maximilianomolina@ascg.mx?subject=Maximiliano Molina',
          linkedIn: '',
        },
      },
    ],
  },
  {
    tab: 'bpo',
    data: [
      {
        name: 'L.C. Diego Medina',
        job: 'job1',
        socialMedia: {
          twitter: '',
          email: 'mailto:diegomedina@ascg.mx?subject=L.C. Diego Medina',
          linkedIn: '',
        },
      },
      {
        name: 'L.C. Jaqueline Velazquez',
        job: 'job2',
        socialMedia: {
          twitter: '',
          email:
            'mailto:jaquelinevelazquez@ascg.mx?subject=L.C. Jaqueline Velazquez',
          linkedIn: '',
        },
      },
      {
        name: 'Miguel Sánchez',
        job: 'job3',
        socialMedia: {
          twitter: '',
          email: 'mailto:miguelsanchez@ascg.mx?subject=Miguel Sánchez',
          linkedIn: '',
        },
      },
      {
        name: 'Arturo Aguilar',
        job: 'job4',
        socialMedia: {
          twitter: '',
          email: 'mailto:arturoa@ascg.mx?subject=Arturo Aguilar',
          linkedIn: '',
        },
      },
      {
        name: 'Josue Torres',
        job: 'job5',
        socialMedia: {
          twitter: '',
          email: 'mailto:josuetorres@ascg.mx?subject=Josue Torres',
          linkedIn: '',
        },
      },
      {
        name: 'Nubia Guadarrama',
        job: 'job6',
        socialMedia: {
          twitter: '',
          email: 'mailto:nubiaguadarrama@ascg.mx?subject=Nubia Guadarrama',
          linkedIn: '',
        },
      },
      {
        name: 'Cinthia Trujillo',
        job: 'job7',
        socialMedia: {
          twitter: '',
          email: 'mailto:cinthiat@ascg.mx?subject=Cinthia Trujillo',
          linkedIn: '',
        },
      },
      {
        name: 'Andrea Medina',
        job: 'job8',
        socialMedia: {
          twitter: '',
          email: 'mailto:andream@ascg.mx?subject=Andrea Medina',
          linkedIn: '',
        },
      },
      {
        name: 'Jannette Lazcano',
        job: 'job9',
        socialMedia: {
          twitter: '',
          email: 'mailto:jannettel@ascg.mx?subject=Jannette Lazcano',
          linkedIn: '',
        },
      },
      {
        name: 'Mario Alberto López',
        job: 'job9',
        socialMedia: {
          twitter: '',
          email: 'mailto:mariol@ascg.mx?subject=Mario Alberto López',
          linkedIn: '',
        },
      },
    ],
  },
  // {
  //   tab: 'contabilidad',
  //   data: [
  //     {
  //       name: 'C.P. Alan Elizalde',
  //       job: 'job1',
  //       socialMedia: {
  //         twitter: '',
  //         email: 'mailto:alanelizalde@ascg.mx?subject=Alan Elizalde',
  //         linkedIn: '',
  //       },
  //     },
  //     {
  //       name: 'C.P. Rocio Vicente',
  //       job: 'job2',
  //       socialMedia: {
  //         twitter: '',
  //         email: 'mailto:rociov@ascg.mx?subject=C.P. Rocio Vicente',
  //         linkedIn: '',
  //       },
  //     },
  //     {
  //       name: 'C.P. Jessica Gonzalez ',
  //       job: 'job2',
  //       socialMedia: {
  //         twitter: '',
  //         email: 'mailto:jessicag@ascg.mx?subject=C.P. Jessica Gonzalez ',
  //         linkedIn: '',
  //       },
  //     },
  //     {
  //       name: 'Rebeca Gómez',
  //       job: 'job3',
  //       socialMedia: {
  //         twitter: '',
  //         email: 'mailto:rebecag@ascg.mx?subject=Rebeca Gómez',
  //         linkedIn: '',
  //       },
  //     },
  //     {
  //       name: 'José Angel Romero',
  //       job: 'job3',
  //       socialMedia: {
  //         twitter: '',
  //         email: 'mailto:angelromero@ascg.mx?subject=José Angel Romero',
  //         linkedIn: '',
  //       },
  //     },
  //     {
  //       name: 'Karina Serrano',
  //       job: 'job4',
  //       socialMedia: {
  //         twitter: '',
  //         email: 'mailto:karinaserrano@ascg.mx?subject=Karina Serrano',
  //         linkedIn: '',
  //       },
  //     },
  //     {
  //       name: 'Estefania Lopez',
  //       job: 'job4',
  //       socialMedia: {
  //         twitter: '',
  //         email: 'mailto:estefanialopez@ascg.mx?subject=Estefania Lopez',
  //         linkedIn: '',
  //       },
  //     },
  //     {
  //       name: 'C.P. Floridalia Hernández',
  //       job: 'job4',
  //       socialMedia: {
  //         twitter: '',
  //         email:
  //           'mailto:floridaliah@ascg.mx?subject=JC.P. Floridalia Hernández',
  //         linkedIn: '',
  //       },
  //     },
  //     {
  //       name: 'Nayeli Hernandez',
  //       job: 'job4',
  //       socialMedia: {
  //         twitter: '',
  //         email: 'mailto:marisol@ascg.mx?subject=Nayeli Hernandez',
  //         linkedIn: '',
  //       },
  //     },
  //     {
  //       name: 'Aquiles  Galvan',
  //       job: 'job4',
  //       socialMedia: {
  //         twitter: '',
  //         email: 'mailto:aquilesg@ascg.mx?subject=Aquiles  Galvan',
  //         linkedIn: '',
  //       },
  //     },
  //     {
  //       name: 'Jaqueline Andrade',
  //       job: 'job5',
  //       socialMedia: {
  //         twitter: '',
  //         email: 'mailto:jaquelinea@ascg.mx?subject=Jaqueline Andrade',
  //         linkedIn: '',
  //       },
  //     },
  //     {
  //       name: 'Rodrigo Palacios',
  //       job: 'job5',
  //       socialMedia: {
  //         twitter: '',
  //         email: 'mailto:joserodrigopalacios@ascg.mx?subject=Rodrigo Palacios',
  //         linkedIn: '',
  //       },
  //     },
  //     {
  //       name: 'Paulo César Santilla',
  //       job: 'job5',
  //       socialMedia: {
  //         twitter: '',
  //         email: 'mailto:paulos@ascg.mx?subject=Paulo César Santilla',
  //         linkedIn: '',
  //       },
  //     },

  //     {
  //       name: 'Litzy  Flores',
  //       job: 'job5',
  //       socialMedia: {
  //         twitter: '',
  //         email: 'mailto:litzyf@ascg.mx?subject=Litzy  Flores',
  //         linkedIn: '',
  //       },
  //     },
  //     {
  //       name: 'Diego Retama',
  //       job: 'job5',
  //       socialMedia: {
  //         twitter: '',
  //         email: 'mailto:yairr@ascg.mx?subject=Diego Retama',
  //         linkedIn: '',
  //       },
  //     },
  //     {
  //       name: 'Jehu Fabian',
  //       job: 'job5',
  //       socialMedia: {
  //         twitter: '',
  //         email: 'mailto:jehuf@ascg.mx?subject=Jehu Fabian',
  //         linkedIn: '',
  //       },
  //     },
  //   ],
  // },

  {
    tab: 'impuestos',
    data: [
      {
        name: 'L.C. Samantha Hernández',
        job: 'job1',
        socialMedia: {
          twitter: '',
          email: 'mailto:samhernandez@ascg.mx?subject=L.C. Samantha Hernández',
          linkedIn: '',
        },
      },
      {
        name: 'C.P.F. Efrain Rodriguez',
        job: 'job2',
        socialMedia: {
          twitter: '',
          email:
            'mailto:efrainrodriguez@ascg.mx?subject=C.P.F. Efrain Rodriguez',
          linkedIn: '',
        },
      },
      {
        name: 'Patricia Palacios',
        job: 'job3',
        socialMedia: {
          twitter: '',
          email: 'mailto:patriciapalacios@ascg.mx?subject=Patricia Palacios',
          linkedIn: '',
        },
      },
      {
        name: 'Javier Espíndola',
        job: 'job3',
        socialMedia: {
          twitter: '',
          email: 'mailto:javierespindola@ascg.mx?subject=Javier Espíndola',
          linkedIn: '',
        },
      },
    ],
  },
  {
    tab: 'impuestos_exp',
    data: [
      {
        name: 'C.P. Amairani Becerril',
        job: 'job1',
        socialMedia: {
          twitter: '',
          email:
            'mailto:amairanibecerril@ascg.mx?subject=C.P. Amairani Becerril',
          linkedIn: '',
        },
      },
      {
        name: 'L.C. Saúl Maza',
        job: 'job2',
        socialMedia: {
          twitter: '',
          email: 'mailto:saulmaza@ascg.mx?subject=L.C. Saúl Maza',
          linkedIn: '',
        },
      },
      {
        name: 'Fernando Anzures',
        job: 'job3',
        socialMedia: {
          twitter: '',
          email: 'mailto:fernandoanzures@ascg.mx?subject=Fernando Anzures',
          linkedIn: '',
        },
      },
      {
        name: 'Biinisa Monjardin',
        job: 'job4',
        socialMedia: {
          twitter: '',
          email: 'mailto:biinisamonjardin@ascg.mx?subject=Biinisa Monjardin',
          linkedIn: '',
        },
      },
    ],
  },
  {
    tab: 'division_internacional',
    data: [
      {
        name: 'Lic. Monserrat Meza',
        job: 'job5',
        socialMedia: {
          twitter: '',
          email: 'mailto:monserratm@ascg.mx?subject=Lic. Monserrat Meza',
          linkedIn: '',
        },
      },
      {
        name: 'Mariana Rojas',
        job: 'job6',
        socialMedia: {
          twitter: '',
          email: 'mailto:mrojas@ascg.mx?subject=Mariana Rojas',
          linkedIn: '',
        },
      },
      {
        name: 'C. Florencia Canseco',
        job: 'job6',
        socialMedia: {
          twitter: '',
          email: 'mailto:florenciacanseco@ascg.mx?subject=C. Florencia Canseco',
          linkedIn: '',
        },
      },
    ],
  },
  // {
  //   tab: 'legal',
  //   data: [
  //     {
  //       name: 'Mtro. Marco Antonio López',
  //       job: 'job1',
  //       socialMedia: {
  //         twitter: '',
  //         email: 'mailto:mlopez@ascg.mx?subject=Lic. Marco Antonio López',
  //         linkedIn: '',
  //       },
  //     },
  //     {
  //       name: 'Lic. Alejandro Vázquez',
  //       job: 'job2',
  //       socialMedia: {
  //         twitter: '',
  //         email:
  //           'mailto:alejandrovazquez@ascg.mx?subject=Lic. Alejandro Vázquez',
  //         linkedIn: '',
  //       },
  //     },

  //     {
  //       name: 'M.D. Verónica W. Zavala',
  //       job: 'job3',
  //       socialMedia: {
  //         twitter: '',
  //         email:
  //           'mailto:veronicazavala@ascg.mx?subject=M.D. Verónica W. Zavala',
  //         linkedIn: '',
  //       },
  //     },

  //     {
  //       name: 'Irazema Rueda',
  //       job: 'job4',
  //       socialMedia: {
  //         twitter: '',
  //         email: 'mailto:irueda@ascg.mx?subject=Irazema Rueda',
  //         linkedIn: '',
  //       },
  //     },

  //     {
  //       name: 'Valeria Soto',
  //       job: 'job5',
  //       socialMedia: {
  //         twitter: '',
  //         email: 'mailto:VSoto@ascg.mx ?subject=Valeria Soto',
  //         linkedIn: '',
  //       },
  //     },

  //     {
  //       name: 'Lic. Sarai Castañeda',
  //       job: 'job6',
  //       socialMedia: {
  //         twitter: '',
  //         email: 'mailto:SarahiC@ascg.mx?subject=Lic. Sarai Castañeda',
  //         linkedIn: '',
  //       },
  //     },
  //     {
  //       name: 'Lic. Martín Rojas',
  //       job: 'job7',
  //       socialMedia: {
  //         twitter: '',
  //         email: 'mailto:martinrojas@ascg.mx?subject=Lic. Martín Rojas',
  //         linkedIn: '',
  //       },
  //     },
  //     {
  //       name: 'M.D.C. Mariana Rios Cruz',
  //       job: 'job8',
  //       socialMedia: {
  //         twitter: '',
  //         email: 'mailto:MarianaR@ascg.mx?subject=M.D.C. Mariana Rios Cruz',
  //         linkedIn: '',
  //       },
  //     },
  //   ],
  // },
  {
    tab: 'marketing_digital',
    data: [
      {
        name: 'Lic. Iván Armijo',
        job: 'job1',
        socialMedia: {
          twitter: '',
          email: 'mailto:ivanarmijo@ascg.mx?subject=Lic. Iván Armijo',
          linkedIn: '',
        },
      },
      {
        name: 'L.D.G Eduardo Burgos',
        job: 'job2',
        socialMedia: {
          twitter: '',
          email: 'mailto:eduardoburgos@ascg.mx?subject=L.D.G Eduardo Burgos',
          linkedIn: '',
        },
      },
    ],
  },
  // {
  //   tab: 'internal_audit',
  //   data: [
  //     {
  //       name: 'C.P. Angélica Sánchez',
  //       job: 'job1',
  //       socialMedia: {
  //         twitter: '',
  //         email: 'mailto:CPAngelica@ascg.mx?subject=C.P. Angélica Sánchez',
  //         linkedIn: '',
  //       },
  //     },
  //     {
  //       name: 'M.B.A. Y C.P.F. Luis Javier Arreguín',
  //       job: 'job2',
  //       socialMedia: {
  //         twitter: '',
  //         email:
  //           'mailto:luisjavier@ascg.mx?subject=M.B.A. Y C.P.F. Luis Javier Arreguín',
  //         linkedIn: '',
  //       },
  //     },
  //     {
  //       name: 'C.P. Jorge Hernández ',
  //       job: 'job3',
  //       socialMedia: {
  //         twitter: '',
  //         email: 'mailto:JorgeH@ascg.mx?subject=C.P. Jorge Hernández ',
  //         linkedIn: '',
  //       },
  //     },
  //   ],
  // },
  // {
  //   tab: 'health',
  //   data: [
  //     {
  //       name: 'M.C. Eranderi Arreguín',
  //       job: 'job1',
  //       socialMedia: {
  //         twitter: '',
  //         email:
  //           'mailto:EranderiArreguin@ascg.mx?subject=M.C. Eranderi Arreguín',
  //         linkedIn: '',
  //       },
  //     },
  //   ],
  // },
  {
    tab: 'sector_financiero',
    data: [
      {
        name: 'Mtro. Marco López',
        job: 'job1',
        socialMedia: {
          twitter: '',
          email: 'mailto:marco.lopez@ascg.mx?subject=Mtro. Marco López',
          linkedIn: '',
        },
      },
      {
        name: 'Lic. Edgar Villareal',
        job: 'job2',
        socialMedia: {
          twitter: '',
          email: 'mailto:edgar.villareal@ascg.mx?subject=Lic. Edgar Villareal',
          linkedIn: '',
        },
      },
      {
        name: 'Lic. Valeria Soto',
        job: 'job3',
        socialMedia: {
          twitter: '',
          email: 'mailto:valeria.soto@ascg.mx?subject=Lic. Valeria Soto',
          linkedIn: '',
        },
      },
      {
        name: 'Lic. Mayra Aguilar',
        job: 'job4',
        socialMedia: {
          twitter: '',
          email: 'mailto:mayra.aguilar@ascg.mx?subject=Lic. Mayra Aguilar',
          linkedIn: '',
        },
      },
      {
        name: 'Lic. Samantha Chávez',
        job: 'job5',
        socialMedia: {
          twitter: '',
          email: 'mailto:samantha.chavez@ascg.mx?subject=Lic. Samantha Chávez',
          linkedIn: '',
        },
      },
      {
        name: 'Lic. Valery Álvarez',
        job: 'job6',
        socialMedia: {
          twitter: '',
          email: 'mailto:valery.alvarez@ascg.mx?subject=Lic. Valery Álvarez',
          linkedIn: '',
        },
      },
    ],
  },
  {
    tab: 'technologies',
    data: [
      {
        name: 'M.Cs. Omar Mata Juarez',
        job: 'job1',
        socialMedia: {
          twitter: '',
          email:
            'mailto:omar.mata@uberprototech.com?subject=M.Cs. Omar Mata Juarez',
          linkedIn: '',
        },
      },
      {
        name: 'Dr. Luis Ibarra',
        job: 'job1',
        socialMedia: {
          twitter: '',
          email: 'mailto:luis.ibarra@uberprototech.com?subject=Dr. Luis Ibarra',
          linkedIn: '',
        },
      },
      {
        name: 'Rocio Reynoso',
        job: 'job2',
        socialMedia: {
          twitter: '',
          email: 'mailto:rocioreynoso@uberprototech.com?subject=Rocio Reynoso',
          linkedIn: '',
        },
      },
      {
        name: 'Alberto Hernández',
        job: 'job3',
        socialMedia: {
          twitter: '',
          email:
            'mailto:albertohernandez@uberprototech.com?subject=Alberto Hernández',
          linkedIn: '',
        },
      },
      {
        name: 'Josue Cano',
        job: 'job4',
        socialMedia: {
          twitter: '',
          email: 'mailto:josuecano@uberprototech.com?subject=Josue Cano',
          linkedIn: '',
        },
      },
    ],
  },
]
