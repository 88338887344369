import React from 'react'
import _ from 'lodash'
import { FormattedMessage } from 'react-intl'

import { Tabs, TabPanel } from 'react-tabs'

import Icon from '../common/Icon'
import { tabsSchema, directorySchema } from './utils'

import {
  CustomTabList,
  CustomTab,
  List,
  ContainerIcons,
  ContentDescription,
  CustomLink,
} from './Tabs.styled'

function handleIcon(type) {
  switch (type) {
    case 'twitter':
      return <Icon icon={['fab', 'twitter']} size="sm" />
    case 'linkedIn':
      return <Icon icon={['fab', 'linkedin-in']} size="sm" />
    default:
      return <Icon icon="at" size="sm" />
  }
}
const Directory = () => (
  <Tabs>
    <CustomTabList>
      {tabsSchema.map(tab => (
        <CustomTab key={tab}>
          <FormattedMessage id={`directory.jobs.${tab}.title`} />
        </CustomTab>
      ))}
    </CustomTabList>
    {directorySchema.map(department => (
      <TabPanel key={department.tab}>
        <List>
          {department.data.map(item => (
            <li key={item.name}>
              <ContentDescription>
                <h4 style={{ margin: 0 }}>{item.name}</h4>
                <FormattedMessage
                  id={`directory.jobs.${department.tab}.${item.job}`}
                  style={{ fontSize: 'small' }}
                />
              </ContentDescription>
              <ContainerIcons>
                {_.map(
                  item.socialMedia,
                  (value, key) =>
                    value && (
                      <CustomLink key={key} href={value}>
                        {handleIcon(key)}
                      </CustomLink>
                    )
                )}
              </ContainerIcons>
            </li>
          ))}
        </List>
      </TabPanel>
    ))}
  </Tabs>
)

export default Directory
