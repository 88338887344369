import styled from 'styled-components'
import { Tab, TabList } from 'react-tabs'

import 'react-tabs/style/react-tabs.css'

const CustomTabList = styled(TabList)`
  margin: 0 0 10px;
  padding: 0;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  position: relative;

  &:hover {
    &::-webkit-scrollbar-thumb {
      background: ${props => props.theme.backgroundDark};
      border-radius: 20px;
      position: absolute;
    }
  }
  &::-webkit-scrollbar {
    position: absolute;
    bottom: 0;
    width: 4px;
    height: 4px;
  }
`

const CustomTab = styled(Tab)`
  display: inline-block;
  width: fit-content;
  min-width: 150px;
  color: ${props => props.theme.textLight};
  background-color: ${props => props.theme.primary};
  border: none;
  border-radius: 0 30px 0 0;
  bottom: -1px;
  left: 0;
  position: relative;
  margin-left: 2px;
  list-style: none;
  padding: 2px 12px;
  cursor: pointer;

  &:first-child {
    margin: 0;
  }

  &.react-tabs__tab--selected {
    background-color: ${props => props.theme.primaryLight};
    border-radius: 0 30px 0 0;
    color: ${props => props.theme.textLight};
    font-weight: bold;
    &:focus {
      outline: none;
    }
  }
`
const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  max-width: 400px;
  & > li {
    padding: 0.5rem 1rem;
    &:nth-child(odd) {
      background-color: rgba(245, 245, 245, 0.5);
    }
  }
`

const CustomLink = styled.a`
  text-decoration: none;
  border: 1px solid ${props => props.theme.backgroundDark};
  color: ${props => props.theme.backgroundDark};
  height: 26px;
  width: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10rem;
  margin: 0 0.5rem;
  &:hover {
    outline: none !important;
    border: 1px solid ${props => props.theme.green};
    box-shadow: 0 0 5px ${props => props.theme.green};
  }
  &:first-child {
    margin-left: 0;
  }
`

const ContentDescription = styled.div`
  line-height: 1.2;
  padding: 1rem 0;
  color: ${props => props.theme.primary};
`

const ContainerIcons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
`

export {
  CustomTabList,
  CustomTab,
  List,
  ContainerIcons,
  ContentDescription,
  CustomLink,
}
