import React from 'react'
import Layout from '../components/Layout'

import image_background from '../images/team-background.jpg'
import SectionHeaderImage from '../components/common/SectionHeaderImage'
import { TitleSection } from '../components/common/Index.styled'
import Section from '../components/common/Section'
import Directory from '../components/Directory'
import { FormattedMessage } from 'react-intl'

const Team = props => (
  <Layout
    title="Nuestro equipo"
    description="Conoce nuestro equipo de trabajo y contactanos, no pierdas esta oportundiad."
    {...props}
  >
    <SectionHeaderImage image={image_background} position="50% 30%" />
    <TitleSection>
      <FormattedMessage id="directory.title" />
    </TitleSection>
    <Section height="600px">
      <Directory />
    </Section>
  </Layout>
)

export default Team
